
import AppNav from "./components/nav";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
    AppNav,
  },
  data() {
    return {
      disabledScroll: false
    };
  },
  methods: {
    afterLogin() {
      axios.defaults.headers.common["Token"] = this.U.token;
      this.initSharing();
    }
  },
  created() {

    var t = this;

    Vue.prototype.dictArea = {
      2: '卓宏',
      3: '科学城',
      4: '深理工'
    };

    Vue.prototype.startUrl = location.href;

    Vue.prototype.afterLogin = t.afterLogin;

    axios.defaults.baseURL = 'https://' + location.host + '/m/';

    axios.interceptors.response.use(function (response) {
      if (response.data.status == -5) {
        localStorage.removeItem('_m');
        location.replace('/login');
      }
      return response;
    }, function (error) {
      if (error.response.status === 401) {
        console.error('Unauthorized access, please login again.');
      } else if (error.response.status === 403) {
        console.error('Access forbidden.');
      }
      return Promise.reject(error);
    });

    Vue.prototype.busying = false;

    Vue.prototype.toggle = {
      expandedNav: true
    };

    Vue.prototype.app2 = location.host.indexOf('ay2') > -1;

    if (/localhost/.test(location.host)) {
      Vue.prototype.app2 = true;
      axios.defaults.baseURL = 'https://ay2.digital-event.cn/m/';
    }

    let app2 = Vue.prototype.app2;

    if (app2) {
      document.body.classList.add('app2');
    }

    if (S.get('_m')) {
      Vue.prototype.U = S.get('_m');
      axios.defaults.headers.common["Token"] = this.U.token;
      t.initSharing();
    }
    else {
      Vue.prototype.U = {
        name: '',
        avatar_url: '',
        token: '',
        id: '',
        open_id: '',
      };
      t.$router.replace('/login');
    }


  },
  mounted() {
    let t = this;
  }

};
